* {
    outline: none !important;
}

a, a :hover {
    text-decoration: none;
}

body {
    padding: 0;
    margin: 0;
    background-color: #333;
    font-family: "Lato", sans-serif;
}

.MuiSelect-icon {
    color: #fff !important;
}

.MuiSelect-selectMenu {
    border: 0px !important;
}

.slider-container {
    width: 100%;
    /* padding-right: 40px; */
}

#root {
    background-color: #fff;
    width: 100%;
    max-width: 414px;
    margin: 0 auto;
}

#menu-rodape, #searchbox, #sharebox {
    width: 100%;
    max-width: 414px;
}

.btn-quantity {
    background: none;
    border-color: #87db41 !important;
    color: #87db41;
    padding: 5px 5px;
    width: 30%;
    text-align: center;
    outline: none;
}

.btn-quantity:active {
    border-style: outset !important;
}

.serrilhado {
    min-height: 200px;
    margin: 0 auto;
    position: relative;
    padding: 10px 5px;
    text-align: center;
  }
  
.serrilhado:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 29px;
    right: 0;
    bottom: -19px;
    left: 0;
    background: 
      linear-gradient(-45deg, transparent 70%, rgb(254 249 195) 75% ), 
      linear-gradient( 45deg, transparent 70%, rgb(254 249 195) 75% );
    background-repeat: repeat-x;
    background-size: 15px 10px, 15px 10px;
    background-position: center;
  }